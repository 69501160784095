import styled from "styled-components";

export const Titulo = styled.h1`
  font-size: 24px !important;
  white-space: nowrap;
`;
export const Texto = styled.p`
  font-size: 12px !important;
  margin-top: 10px;
  text-align: center;
  line-height: 2!important;
  text-align: justify;
  padding: 10px;
`;

export const Link = styled.a`
  font-size: 14px !important;
  text-decoration: none;
  margin-top: 5px;
  line-height: 2;
  color: #777;
  font-weight: bold;
  img{
    margin-right: 5px;
  }
`;