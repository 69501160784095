import LayoutSite from "components/LayoutSite";
import * as React from "react";
import {useState } from "react";
import CardOHotel from "components/Card-OHotel";
import CategoriaSuites from "components/CategoriaSuites";
import Gastronomia from "components/Gastronomia";
import BeautySpa from "components/BeautySpa";
import listaArquivos from "oci/listaArquivos";
import axios from "axios";
import ContainerSite from "components/EstruturaSite/ContainerSite";
import GaleriaHotel from "components/GaleriaHotel";
import TituloDourado from "components/TextoSite/TituloDourado";
import { BlocoSite } from "styles/styles";
import { dataCookie } from "funcoes/dataCookie";
import { getCsrfToken } from "funcoes/getToken";

export async function getStaticProps(ctx: any) {
  const fotosOHotel = await listaArquivos("Site/Fotos/mosaico-home/");
  const fotosDayCard = await listaArquivos("Site/Fotos/mosaico-rodape/");
  const getfotosBeautySpa = await listaArquivos(
    "Site/Fotos/galeria-beauty-spa/"
  );
  const fotosSuites = await listaArquivos("Site/Fotos/categoria-de-suite/");
  const sessionId = await dataCookie({ ctx });
  const csrfToken = await getCsrfToken(sessionId);
  const suites = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}suites`, {
    headers: {
      Authorization: `Bearer ${csrfToken}`, // Exemplo de um token Bearer
      "Content-Type": "application/json",
      "x-csrf-token": csrfToken,
      "session-id": sessionId,
    },
  });
  const fotosBeautySpa = getfotosBeautySpa.filter(
    (imagem: any) => !imagem.includes(`thumb`)
  );
  const galeria = await listaArquivos("Site/Fotos/galeria-ohotel/");

  const fotosGaleria = galeria.filter(
    (imagem: any) => !imagem.includes(`thumb`)
  );
  const suiteComFoto = suites.data.map((suite: any) => {
    const imagens = fotosSuites.filter(
      (imagem: any) =>
        imagem.includes(`categoria-de-suite/${suite.url_suite}/`) &&
        imagem.includes(`.`)
    );
    suite.imagens = imagens;
    return suite;
  });
  return {
    props: {
      fotosOHotel,
      suites: suiteComFoto,
      fotosBeautySpa: fotosBeautySpa,
      fotosDayCard: fotosDayCard,
      galeria: fotosGaleria,
    },
    revalidate: 30,
  };
}

export default function Home(props: any) {
  const [ativo, setAtivo] = useState(false);
  return (
    <LayoutSite ativaMenu={ativo}>
      <ContainerSite>
        <CardOHotel fotosOHotel={props.fotosOHotel} />
        <CategoriaSuites suites={props.suites} exibeCaracteristicas={false} />
        <Gastronomia />
        <BeautySpa fotos={props.fotosBeautySpa} />
        <BlocoSite>
          <TituloDourado>GALERIA DE FOTOS</TituloDourado>
          <GaleriaHotel fotos={props.galeria} />
        </BlocoSite>
      </ContainerSite>
    </LayoutSite>
  );
}
