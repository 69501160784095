import styled from "styled-components";
import "keen-slider/keen-slider.min.css"

export const Titulo = styled.h1`
  font-size: 24px !important;
  white-space: nowrap;
  line-height: 1.5;
`;
export const Texto = styled.p`
  font-size: 14px !important;
  margin-top: 10px;
  text-align: center;
  line-height: 2 !important;
  text-align: justify;
  padding: 10px;
`;

export const Container = styled.div`
  width: 100%;
  [class^="number-slide"],
  [class*=" number-slide"] {
    margin-bottom: 2px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff!important;
    border: none!important;
  }
`;

export const KeenSlider = styled.div`
  font-size: 30px;
  margin-bottom: 10px;
  height: auto;
`;

export const KeenSliderThumbnail = styled.div`
  height: auto;
  border: none!important;
  background-color: #fff;
`;

export const ImageSlide = styled.img`
  width: 100%;
  height: auto;
`;
