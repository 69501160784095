import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function VideoHome() {
  // NEXT_PUBLIC_BUCKET_NAME
  // NEXT_PUBLIC_BUCKET_URL

  const [isClient, setIsClient] = useState(false);
  const videoRef = useRef(null);
  return (
    <Grid container sx={{ width: "100%" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <div>
          <video
            autoPlay={true}
            muted
            loop
            className="videoHome"
            style={{ maxWidth: "100%", width: "100%", overflow: "hidden" }}
            width={"100%"} // Sombra nas bordas
            preload="auto"
            poster={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Videos/poster-video-desktop-min.png`}
          >
            <source
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Videos/video-home.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
        {/* <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            top: "0px",
            boxShadow: "inset 0 2px 8px rgba(0, 0, 0, 0.5)",
            overflow: "hidden",
          }}
        ></div> */}
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <div>
          <video
            playsInline
            autoPlay={true}
            muted={true}
            loop={true}
            className="videoHomeMobile"
            style={{ maxWidth: "100%", width: "100%" }} // Sombra nas bordas
            ref={videoRef}
            preload="auto"
            poster={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Videos/poster-video.PNG`}
          >
            <source
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Videos/video-home-mobile.webm`}
              type="video/webm"
            />
            <source
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Videos/video-home-mobile.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
        {/* <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100%",
            backgroundColor: "transparent",
            top: "0px",
            boxShadow: "inset 0 2px 8px rgba(0, 0, 0, 0.5)",
          }}
        ></div> */}
      </div>
    </Grid>
  );
}
