import { createTheme, Grid, ThemeProvider} from "@mui/material";
import VideoHome from "./VideoHome";
import * as S from "styles/styles";
import FooterSite from "./FooterSite";
import TopoSite from "./TopoSite";
import FooterCalendarioRanger from "./FooterCalendario/FooterCalendarioRanger";
import WhatsappContato from "./WhatsappContato";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#66d7d5",
      light: "#D8F4F3",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#f0851b",
    },
  },
});

export default function LayoutSite(props: any) {
  return (
    <ThemeProvider theme={theme}>
      <S.divRelative>
        <Grid container justifyContent={"center"}>
          <TopoSite />
        </Grid>
        <Grid container marginTop={"70px"}>
          <VideoHome />
        </Grid>

        {/* <S.divMenuContent className={ativoScroll ? "ativoScroll contentdiv":"contentdiv"}>
          <Navbar />
        </S.divMenuContent> */}
      </S.divRelative>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        <Grid
          container
          justifyContent={"center"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {props.children}
        </Grid>
      </Grid>

      <FooterSite />
      <FooterCalendarioRanger />
      <WhatsappContato />
    </ThemeProvider>
  );
}
