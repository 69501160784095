import styled from "styled-components";

export const TituloOHotel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

export const Divisao = styled.div`
  display: flex;
  height: 100%;
`;
export const DivTexto = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
`;
export const TextoOHotel = styled.p`
  font-size: 14px !important;
  line-height: 2 !important;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
