/* eslint-disable @next/next/no-img-element */
import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { Link, Texto, Titulo } from "./style";
import ButtonLaranja from "components/Botoes/ButtonLaranja";
import Descricao from "components/TextoSite/Descricao";
import TituloDourado from "components/TextoSite/TituloDourado";
import Image from "next/image";
import ButtonSaibaMais from "components/Botoes/ButtonSaibaMais";
import { BlocoSite } from "styles/styles";
import LAYOUT from "components/Constants";

export default function Gastronomia() {
  const [restauranteSelect, setRestauranteSelect] = useState(0);
  const handleChange = (event: any, newValue: any) => {
    setRestauranteSelect(newValue);
  };
  return (
    <BlocoSite>
      <TituloDourado>GASTRONOMIA DPNY</TituloDourado>
      <Grid container justifyContent={"center"}>
        <Tabs
          className="tabDPNY"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={restauranteSelect}
          onChange={handleChange}
          sx={{ marginTop: "25px", padding: "0px 20px" }}
        >
          <Tab
            label="TRÓIA"
            id={`simple-tab-0`}
            className="tabDPNY"
            aria-controls={`simple-tabpanel-0`}
          />
          <Tab
            label="BISTRÔ"
            id={`simple-tab-1`}
            className="tabDPNY"
            aria-controls={`simple-tabpanel-1`}
          />
          <Tab
            label="SUSHI LOUNGE"
            id={`simple-tab-2`}
            className="tabDPNY"
            aria-controls={`simple-tabpanel-2`}
          />
          <Tab
            label="TERRACE"
            id={`simple-tab-3`}
            className="tabDPNY"
            aria-controls={`simple-tabpanel-3`}
          />
          <Tab
            label="BEACH CLUB"
            id={`simple-tab-4`}
            className="tabDPNY"
            aria-controls={`simple-tabpanel-4`}
          />
        </Tabs>
      </Grid>
      <div
        role="tabpanel"
        hidden={restauranteSelect !== 0}
        id={`simple-tabpanel-0`}
        aria-labelledby={`simple-tab-0`}
        style={{ marginTop: LAYOUT.margin.m2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent={"space-between"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            justifyContent="start"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginTop={"10px"}
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/logo-restaurantes/logo-troia.png`}
                width={200}
                height={150}
                alt="Restaurante Tóia"
                className="logoGastronomia"
              />
              <Grid container direction="column" justifyContent="center">
                <Descricao>
                  Gastronomia saborosa e com pratos muito bem executados em um
                  ambiente elegante à luz de velas e uma localização
                  privilegiada vista mar. Excelente para aqueles que desejam
                  celebrar um jantar especial.
                </Descricao>
                <Descricao size="14px" marginTop="30px" textAlign="left">
                  Segunda a Sábado das 19:00 às 22:30
                </Descricao>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  margin={"25px 0px 0px 0px"}
                >
                  <Link href="https://api.whatsapp.com/send?phone=5512996097692">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-whats.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>
                        +55 12 99609-7692
                      </span>
                    </Grid>
                  </Link>
                  <Link href="tel:+551238943000">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-tel.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>+55 12 3894-3000</span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  marginTop="25px"
                  marginBottom={"30px"}
                >
                  <ButtonSaibaMais largura="auto" link={true} url="https://gastronomia.dpny.com.br/troia" target="_blank">
                    SAIBA MAIS
                  </ButtonSaibaMais>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            position={"relative"}
            sx={{ width: "100%", height: "auto" }}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/restaurantes/troia/troia-01.jpg`}
              width={400}
              height={400}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              loading="lazy"
              alt="Restaurante Troia"
            />
          </Grid>
        </Grid>
      </div>
      <div
        role="tabpanel"
        hidden={restauranteSelect !== 1}
        id={`simple-tabpanel-1`}
        aria-labelledby={`simple-tab-1`}
        style={{ marginTop: LAYOUT.margin.m2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent={"space-between"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            justifyContent="start"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginTop={"10px"}
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/logo-restaurantes/logo-bistro.png`}
                width={200}
                height={150}
                alt="Restaurante Bistro"
                className="logoGastronomia"
              />
              <Grid container direction="column" justifyContent="center">
                <Descricao>
                  Aberto dia e noite você pode desfrutar de uma deliciosa
                  variedade de pratos, porções e drinks preparados com atenção
                  aos detalhes para criar uma experiencia saborosa em um cenário
                  paradisíaco pé na areia.
                </Descricao>
                <Descricao size="14px" marginTop="30px">
                  Todos os dias das 18:00 às 22:30
                </Descricao>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  margin={"25px 0px 0px 0px"}
                >
                  <Link href="https://api.whatsapp.com/send?phone=5512996097692">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-whats.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>
                        +55 12 99609-7692
                      </span>
                    </Grid>
                  </Link>
                  <Link href="tel:+551238943000">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-tel.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>+55 12 3894-3000</span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  marginTop="25px"
                  marginBottom={"30px"}
                >
                  <ButtonSaibaMais largura="auto" link={true} url="https://gastronomia.dpny.com.br/bistro" target="_blank">
                    SAIBA MAIS
                  </ButtonSaibaMais>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            position={"relative"}
            sx={{ width: "100%", height: "auto" }}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/restaurantes/bistro/bistro-01.jpg`}
              width={400}
              height={400}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              loading="lazy"
              alt="Restaurante Bistro"
            />
          </Grid>
        </Grid>
      </div>
      <div
        role="tabpanel"
        hidden={restauranteSelect !== 2}
        id={`simple-tabpanel-2`}
        aria-labelledby={`simple-tab-2`}
        style={{ marginTop: LAYOUT.margin.m2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent={"space-between"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            justifyContent="start"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginTop={"10px"}
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/logo-restaurantes/logo-sushi.png`}
                width={200}
                height={150}
                alt="Restaurante Sushi"
                className="logoGastronomia"
              />
              <Grid container direction="column" justifyContent="center">
                <Descricao>
                  Uma culinária asiática com produtos de altíssima qualidade e
                  frescor oferecido durante o dia no nosso beach club e de noite
                  no Terrace com vista mar.
                </Descricao>
                <Descricao size="14px" marginTop="30px">
                  Segunda a Quarta das 12:00 às 18:30
                </Descricao>
                <Descricao margin="0px">
                  Quinta a Domingo das 12:00 às 22:00
                </Descricao>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  margin={"25px 0px 0px 0px"}
                >
                  <Link href="https://api.whatsapp.com/send?phone=5512996097692">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-whats.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>
                        +55 12 99609-7692
                      </span>
                    </Grid>
                  </Link>
                  <Link href="tel:+551238943000">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-tel.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>+55 12 3894-3000</span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  marginTop="25px"
                  marginBottom={"30px"}
                >
                  <ButtonSaibaMais largura="auto" link={true} url="https://gastronomia.dpny.com.br/sushi" target="_blank">
                    SAIBA MAIS
                  </ButtonSaibaMais>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            position={"relative"}
            sx={{ width: "100%", height: "auto" }}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/restaurantes/sushi/sushi-01.jpg`}
              width={400}
              height={400}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              loading="lazy"
              alt="Restaurante Sushi Lounge"
            />
          </Grid>
        </Grid>
      </div>
      <div
        role="tabpanel"
        hidden={restauranteSelect !== 3}
        id={`simple-tabpanel-3`}
        aria-labelledby={`simple-tab-3`}
        style={{ marginTop: LAYOUT.margin.m2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent={"space-between"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            justifyContent="start"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginTop={"10px"}
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/logo-restaurantes/logo-terrace.png`}
                width={200}
                height={150}
                alt="Restaurante Terrace"
                className="logoGastronomia"
              />
              <Grid container direction="column" justifyContent="center">
                <Descricao>
                  Com uma localização privilegiada vista mar para desfrutar de
                  uma variedade de produtos extremamente frescos da gastronomia
                  mediterrânea. Ideal para aqueles que gostam de compartilhar um
                  almoço de dar água na boca.
                </Descricao>
                <Descricao size="15px" marginTop="30px">
                  Todos os dias das 12:00 às 18:00
                </Descricao>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  margin={"25px 0px 0px 0px"}
                >
                  <Link href="https://api.whatsapp.com/send?phone=5512996097692">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-whats.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>
                        +55 12 99609-7692
                      </span>
                    </Grid>
                  </Link>
                  <Link href="tel:+551238943000">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-tel.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>+55 12 3894-3000</span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  marginTop="25px"
                  marginBottom={"30px"}
                >
                  <ButtonSaibaMais largura="auto" link={true} url="https://gastronomia.dpny.com.br/terrace" target="_blank">
                    SAIBA MAIS
                  </ButtonSaibaMais>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            position={"relative"}
            sx={{ width: "100%", height: "auto" }}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/restaurantes/terrace/terrace-01.jpg`}
              width={400}
              height={400}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              loading="lazy"
              alt="Restaurante Terrace"
            />
          </Grid>
        </Grid>
      </div>
      <div
        role="tabpanel"
        hidden={restauranteSelect !== 4}
        id={`simple-tabpanel-4`}
        aria-labelledby={`simple-tab-4`}
        style={{ marginTop: LAYOUT.margin.m2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent={"space-between"}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Grid
            container
            direction="row"
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            justifyContent="start"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              marginTop={"10px"}
            >
              <Image
                src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/logo-restaurantes/logo-beach.png`}
                width={200}
                height={150}
                alt="Restaurante Beach Club"
                className="logoGastronomia"
              />
              <Grid container direction="column" justifyContent="center">
                <Descricao>
                  Uma deliciosa experiência gastronômica aguarda os hóspedes e
                  visitantes em cada um dos quatro restaurantes que servem uma
                  emocionante seleção de pratos frescos e com muita paixão no
                  seu preparo. Em nossa estrutura há uma grande variedade de
                  locais atraentes para desfrutar de um coquetel refrescante e
                  uma bebida exclusiva e relaxar o corpo e a mente em perfeita
                  harmonia com a natureza.
                </Descricao>
                <Descricao size="15px" marginTop="30px">
                  Todos os dias das 11:00 às 18:00
                </Descricao>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  margin={"25px 0px 0px 0px"}
                >
                  <Link href="https://api.whatsapp.com/send?phone=5512996097692">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-whats.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>
                        +55 12 99609-7692
                      </span>
                    </Grid>
                  </Link>
                  <Link href="tel:+551238943000">
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <img
                        src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/icones/ico-tel.jpeg`}
                        alt=""
                        width={"18px"}
                      />
                      <span style={{ fontSize: "15px" }}>+55 12 3894-3000</span>
                    </Grid>
                  </Link>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  marginTop="25px"
                  marginBottom={"30px"}
                >
                  <ButtonSaibaMais largura="auto" link={true} url="https://gastronomia.dpny.com.br/beach-club" target="_blank">
                    SAIBA MAIS
                  </ButtonSaibaMais>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            position={"relative"}
            sx={{ width: "100%", height: "auto" }}
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/restaurantes/beachclub/beach-club-01.jpg`}
              width={400}
              height={400}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
              loading="lazy"
              alt="Restaurante Beach Club"
            />
          </Grid>
        </Grid>
      </div>
    </BlocoSite>
  );
}
