import { Divider } from "@mui/material";
import {
  Container,
  TituloOHotel,
  TextoOHotel,
  Divisao,
  DivTexto,
} from "./styles";
import { useEffect } from "react";
import { useSiteContexto } from "context/context";
import Descricao from "components/TextoSite/Descricao";
import TituloDourado from "components/TextoSite/TituloDourado";
import LAYOUT from "components/Constants";
import Grid from "@mui/material/Grid2";

interface TituloO_HotelInterf {
  tamanhoTela: boolean;
}

export default function TituloO_Hotel() {
  const { tamanhoTela } = useSiteContexto();
  return (
    <>
      <Grid
        container
        direction={"row"}
        className="desktopMenu"
        sx={{ alignItems: "stretch", flexWrap: "nowrap" }}
      >
        <Grid
          container
          margin={"0px"}
          padding={"0px"}
          sx={{
            width: "auto",
            alignItems: "center",
          }}
        >
          <TituloDourado
            sx={{
              flexWrap: "nowrap",
              display: "flex",
              margin: "0px",
              padding: "0px",
              textWrap: "nowrap",
            }}
          >
            DPNY BEACH HOTEL & SPA
          </TituloDourado>
        </Grid>
        <Grid sx={{ flexWrap: "nowrap", height: "auto" }}>
          <Divisao>
            <Divider
              orientation="vertical"
              style={{
                minHeight: "60px",
                height: "auto",
                maxHeight: "auto",
                position: "relative",
                display: "flex",
                borderWidth: "1px",
                color: "#000",
                borderColor: LAYOUT.colors.dourado,
                margin: "0px 40px",
              }}
            />
          </Divisao>
        </Grid>
        <Grid container sx={{ height: "auto",width: "auto", alignItems: "center", flex: "1" }}>
          <Descricao sx={{ margin: "0px", padding: "0px"}}>
          Eleito em 2024 novamente entre os 13 Melhores Hotéis de todo o Brasil e selecionado entre os 1% dos Melhores Hotéis do Mundo. Desfrute de uma combinação de instalações criativas e luxuosas, uma estrutura única de praia, SPA e Parque particular com vista mar, suítes com design artesanal luxuoso, gastronomia e serviços de excelência.
          </Descricao>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="start"
        className="mobileMenu"
      >
        <TituloDourado padding="0px">DPNY BEACH HOTEL & SPA</TituloDourado>
        <Descricao>
        Eleito em 2024 novamente entre os 13 Melhores Hotéis de todo o Brasil e selecionado entre os 1% dos Melhores Hotéis do Mundo. Desfrute de uma combinação de instalações criativas e luxuosas, uma estrutura única de praia, SPA e Parque particular com vista mar, suítes com design artesanal luxuoso, gastronomia e serviços de excelência.
        </Descricao>
      </Grid>
    </>
  );
}
