
import LAYOUT from "components/Constants";
import { HTMLAttributeAnchorTarget, ReactNode } from "react";

interface ButtonSaibaMaisProps {
  largura?: string;
  altura?: string;
  link?: boolean;
  url?: string;
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
  children: ReactNode;
  type?: "submit" | "button";
}

export default function ButtonSaibaMais({
  largura,
  altura,
  type = "button",
  link = false,
  url = "",
  target="_self",
  onClick,
  children,
}: ButtonSaibaMaisProps) {
  if (!link) {
    return (
      <button
        type={type}
        onClick={onClick}
        style={{ width: largura, height: altura, border: "none", backgroundColor: "#fff", cursor: "pointer" }}
      >
        {children}
      </button>
      // <Button variant="contained" color="secondary" className="btnLaranja" onClick={props.onClick}>Reservar</Button>
    );
  }else{
    return (
        <a
          className="btnSaibaMais"
          href={url}
          target={target}
          style={{ width: largura, height: altura, textDecoration: "none", textAlign: "center" }}
        >
          {children}
        </a>
        // <Button variant="contained" color="secondary" className="btnLaranja" onClick={props.onClick}>Reservar</Button>
      );
  }
}
