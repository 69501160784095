import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Descricao from "components/TextoSite/Descricao";
import TituloDourado from "components/TextoSite/TituloDourado";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination} from "swiper/modules";
import Grid from '@mui/material/Grid2';

// Ative os módulos que você vai usar
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import LAYOUT from "components/Constants";
import { BlocoSite } from "styles/styles";
import { Circle } from "@mui/icons-material";

export default function CategoriaSuites({
  suites,
  exibeCaracteristicas = true,
}: any) {
  const [suiteSelect, setSuiteSelect] = useState(0);
  const handleChange = (event: any, newValue: any) => {
    setSuiteSelect(newValue);
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '"></span>';
    },
  };

  const idsPermitidos = process.env.NEXT_PUBLIC_SUITES?.split(",").map(Number) || [];

  return (
    (<BlocoSite>
      <Grid container justifyContent={"center"}>
        <TituloDourado marginTop="0px" marginBottom="0px">
          CATEGORIAS DE SUÍTES
        </TituloDourado>
        <Descricao>
        Nossas 84 suítes possuem um design artesanal luxuoso e peças únicas produzidas pela mão de obra local e de origem brasileira. Cada suíte com todo o luxo e conforto oferecido em nossa estrutura.
        </Descricao>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: exibeCaracteristicas ? 12 : 8
          }}>
          <Tabs
            className="tabDPNY"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={suiteSelect}
            onChange={handleChange}
            sx={{
              maxWidth: "100%",
              marginTop: LAYOUT.margin.m2,
              padding: `0px ${LAYOUT.padding.m2}`,
            }}
          >
            {suites.length > 0
              ? suites.filter((suite: any) => idsPermitidos.includes(suite.id_suite)).map((suite: any, index: number) => {
                  return (
                    <Tab
                      key={index}
                      label={suite.nome_suite}
                      id={`suite-tab-${suite.id_suite}`}
                      className="tabDPNY"
                      aria-controls={`suite-tabpanel-${suite.id_suite}`}
                    />
                  );
                })
              : ""}
          </Tabs>
          {suites.length > 0
            ? suites.filter((suite: any) => idsPermitidos.includes(suite.id_suite)).map((suite: any, index: number) => {
                const caracteristicas = suite.descricao_suite.split(";");
                return (
                  (<div
                    key={index}
                    role="tabpanel"
                    hidden={suiteSelect !== index}
                    id={`suite-tabpanel-${suite.id_suite}`}
                    aria-labelledby={`suite-tab-${suite.id_suite}`}
                    style={{ marginTop: LAYOUT.margin.m4 }}
                  >
                    {suiteSelect === index ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent={"center"}
                        size={{
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 12,
                          xl: 12
                        }}>
                        <Grid
                          sx={{ display: "grid" }}
                          size={{
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: exibeCaracteristicas ? 6 : 10
                          }}>
                          {suite.imagens.length > 0 ? (
                            <Swiper
                              slidesPerView={1}
                              pagination={pagination}
                              modules={[Navigation, Pagination]}
                              navigation
                              style={{
                                width: "100%",
                                height: "100%",
                                zIndex: 0,
                                marginBottom: "30px",
                              }}
                            >
                              {suite.imagens.map(
                                (imagem: any, index: number) => (
                                  <SwiperSlide
                                    key={index}
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/${imagem}`}
                                      alt={imagem.split("/")[4]}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </SwiperSlide>
                                )
                              )}
                            </Swiper>
                          ) : (
                            ""
                          )}
                        </Grid>
                        {exibeCaracteristicas ? (
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="start"
                            size={{
                              xs: 12,
                              sm: 12,
                              md: 6,
                              lg: 6,
                              xl: 6
                            }}>
                            <Grid
                              className="caracteristicasSuite"
                              container
                              direction="column"
                            >
                              <Grid
                                container
                                direction="row"
                                marginTop={"35px"}
                                justifyContent={"start"}
                              >
                                {caracteristicas.length > 0
                                  ? caracteristicas.map(
                                      (c: any, index: number) => {
                                        if (c !== "Sem vista mar") {
                                          return (
                                            (<Grid
                                              container
                                              className="caracteristicasSuite-item"
                                              key={index}
                                              // marginLeft={"20px"}
                                              marginLeft={"10px"}
                                              size={{
                                                xs: 12,
                                                sm: 6,
                                                md: 10,
                                                lg: 12,
                                                xl: 12
                                              }}>
                                              <Circle
                                                className="iconCaracteristicas"
                                                htmlColor={
                                                  LAYOUT.suites.caracteristicas
                                                    .icon.color
                                                }
                                                style={{
                                                  marginRight: "5px",
                                                }}
                                              />
                                              <p
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {c}
                                              </p>
                                            </Grid>)
                                          );
                                        }
                                      }
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                    ) : (
                      ""
                    )}
                  </div>)
                );
              })
            : ""}
        </Grid>
      </Grid>
    </BlocoSite>)
  );
}
