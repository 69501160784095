import { ImageList, ImageListItem } from "@mui/material";
import TituloO_Hotel from "./TituloOHotel";
import { useEffect, useState } from "react";
import listaArquivos from "oci/listaArquivos";
import { BlocoSite } from "styles/styles";
import Image from "next/image";
import Grid from '@mui/material/Grid2';

interface CardOHotelProps {
  fotosOHotel: [];
}

export default function CardOHotel({ fotosOHotel }: CardOHotelProps) {
  return (
    <>
      <BlocoSite>
        <TituloO_Hotel />
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
          marginTop={"25px"}
        >
          <ImageList
            variant="quilted"
            cols={2}
            style={{
              margin: "0px",
              padding: "0px",
              width: "100%",
              height: "auto",
              overflow: "hidden",
            }}
          >
            {fotosOHotel.length > 0
              ? fotosOHotel.map((image: any, index: number) => {
                  return (
                    <ImageListItem
                      key={index}
                      rows={index == 1 || index == 2 ? 2 : 1}
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      {/* <img
                        src={`https://grbna1uwbzo5.compat.objectstorage.sa-saopaulo-1.oraclecloud.com/dpny.com.br/${image}`}
                        alt="Mosaico de imagens do Hotel DPNY"
                        style={{ width: "100%", height: "100%", margin: "0px" }}
                      /> */}
                      <Image
                        src={`https://grbna1uwbzo5.compat.objectstorage.sa-saopaulo-1.oraclecloud.com/dpny.com.br/${image}`}
                        width={index == 1 || index == 2 ? 1024 : 769}
                        height={index == 1 || index == 2 ? 1024 : 961}
                        quality={100}
                        className="desktopImagemMosaico"
                        alt={"Imagens Hotel DPNY"}
                      />
                    </ImageListItem>
                  );
                })
              : ""}
          </ImageList>
        </Grid>
      </BlocoSite>
    </>
  );
}
