/* eslint-disable @next/next/no-img-element */
import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";
import { MutableRefObject, useState } from "react";
import {
  Texto,
  Titulo,
  KeenSlider,
  Container,
  ImageSlide,
  KeenSliderThumbnail,
} from "./styles";
import {
  useKeenSlider,
  KeenSliderPlugin,
  KeenSliderInstance,
} from "keen-slider/react";
import Descricao from "components/TextoSite/Descricao";
import TituloDourado from "components/TextoSite/TituloDourado";
import ButtonSaibaMais from "components/Botoes/ButtonSaibaMais";
import { BlocoSite } from "styles/styles";
import { Divisao, TituloOHotel } from "components/Card-OHotel/styles";
import LAYOUT from "components/Constants";
import Grid from "@mui/material/Grid2";

function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}

function Arrow(props: {
  disabled: boolean;
  left?: boolean;
  onClick: (e: any) => void;
}) {
  const disabeld = props.disabled ? " swiper-button-disabled" : "";
  return (
    <div
      className={
        props.left
          ? `swiper-button-prev ${disabeld}`
          : `swiper-button-next ${disabeld}`
      }
      onClick={props.onClick}
    ></div>
  );
}

export default function BeautySpa({ fotos }: any) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      breakpoints: {
        "(min-width: 0px)": {
          slides: { perView: 4, spacing: 5 },
        },
        "(min-width: 430px)": {
          slides: { perView: 4, spacing: 1 },
        },
        "(min-width: 700px)": {
          slides: { perView: 6, spacing: 1 },
        },
        "(min-width: 1200px)": {
          slides: { perView: 8, spacing: 1 },
        },
        "(min-width: 1536px)": {
          slides: { perView: 8, spacing: 1 },
        },
      },
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );
  return (
    <BlocoSite>
      <Grid
        container
        direction={"row"}
        className="desktopMenu"
        sx={{ alignItems: "stretch", flexWrap: "nowrap" }}
      >
        <Grid
          container
          margin={"0px"}
          padding={"0px"}
          sx={{
            width: "auto",
            alignItems: "center",
          }}
        >
          <TituloDourado
            sx={{
              flexWrap: "nowrap",
              display: "flex",
              margin: "0px",
              padding: "0px",
              textWrap: "nowrap",
            }}
          >
            BEAUTY SPA & SAÚDE
          </TituloDourado>
        </Grid>
        <Grid sx={{ flexWrap: "nowrap", height: "auto" }}>
          <Divisao>
            <Divider
              orientation="vertical"
              style={{
                minHeight: "60px",
                height: "auto",
                maxHeight: "auto",
                position: "relative",
                display: "flex",
                borderWidth: "1px",
                color: "#000",
                borderColor: LAYOUT.colors.dourado,
                margin: "0px 40px",
              }}
            />
          </Divisao>
        </Grid>
        <Grid
          container
          sx={{
            height: "auto",
            width: "auto",
            alignItems: "center",
            flex: "1",
          }}
        >
          <Descricao sx={{ margin: "0px", padding: "0px" }}>
            Um espaço para desconectar e relaxar com procedimentos para levar o
            corpo e a mente a um estado de puro bem-estar.
          </Descricao>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="start"
        className="mobileMenu"
      >
        <TituloDourado padding="0px">BEAUTY SPA & SAÚDE</TituloDourado>
        <Descricao>
          Um espaço para desconectar e relaxar com procedimentos para levar o
          corpo e a mente a um estado de puro bem-estar.
        </Descricao>
      </Grid>
      <Grid
        container
        marginBottom={"30px"}
        justifyContent={"center"}
        marginTop={"25px"}
      >
        <ButtonSaibaMais link={true} url="/tarifas/beauty-spa-saude">
          SAIBA MAIS
        </ButtonSaibaMais>
      </Grid>
      <Container ref={sliderRef} className="keen-slider">
        {fotos.length > 0 ? (
          <>
            {fotos.map((foto: any, index: number) => {
              return (
                <KeenSlider
                  key={index}
                  className={`keen-slider__slide number-slide${index}`}
                >
                  <ImageSlide
                    src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/${foto}`}
                    alt="Galeria Beauty SPA & Saúde"
                  />
                </KeenSlider>
              );
            })}
            {loaded && instanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                  disabled={currentSlide === 0}
                />

                <Arrow
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                  disabled={
                    currentSlide ===
                    instanceRef.current.track.details.slides.length - 1
                  }
                />
              </>
            )}
          </>
        ) : (
          ""
        )}
      </Container>
      <Container ref={thumbnailRef} className="keen-slider thumbnail">
        {fotos.length > 0
          ? fotos.map((foto: any, index: number) => {
              const imagem = foto.split("/");
              return (
                <KeenSliderThumbnail
                  key={index}
                  className={`keen-slider__slide number-slide${index}`}
                >
                  <ImageSlide
                    src={`${process.env.NEXT_PUBLIC_BUCKET_URL}${process.env.NEXT_PUBLIC_BUCKET_NAME}/Site/Fotos/galeria-beauty-spa/thumb/${imagem[3]}`}
                    alt="spa"
                  />
                </KeenSliderThumbnail>
              );
            })
          : ""}
      </Container>
      {loaded && instanceRef.current && instanceRef.current != null && (
        <div className="dots">
          {Array.from(
            { length: instanceRef.current.track.details.slides.length },
            (_, i) => i
          ).map((idx: any) => {
            return (
              <button
                title="miniatura"
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
    </BlocoSite>
  );
}
